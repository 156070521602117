<template>
    <div class="page_content">
        <div class="page_detail">

            <Form ref="form_data" style="margin-left: -30px;" @handleChange="handleChange" @handleSubmit="handleSubmit"
                :form_data="form_data">
                <template slot='line_type1'>
                    <p>
                        <a-divider orientation="left">行程打卡</a-divider>
                    </p>
                </template>
                <template slot='line_type'>
                    <p>
                        <a-divider orientation="left">酒店打卡</a-divider>
                    </p>
                </template>
            </Form>

            <!--  <div class="box_form box_form_btns">
                <a-button type="defalut" @click="$router.back()">返回</a-button>
            </div> -->

        </div>
    </div>
</template>

<script>
    import Form from "@/components/Form";
    import {
        clockSetting,
        getClockSetting
    } from '@/api/evection.js'
    import Avatar from "@/components/small/Avatar";
    export default {
        components: {
            Form
        },
        data() {
            return {
                form_data: {
                    show_submit_btn: true,
                    show_close_btn: false,
                    close_reset_btn: true,
                    list: [{
                            type: 'slot',
                            name: 'line_type1'
                        },
                        {
                            type: "tree-select",
                            name: "department_id",
                            title: "适用部门",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "tree-select",
                            name: "role_id",
                            title: "适用岗位",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        // {
                        //     type: "select",
                        //     name: "role_id",
                        //     title: "适用岗位",
                        //     mode: "default",
                        //     options: {},
                        //     list: [],
                        //     mode: 'multiple'
                        // },
                        {
                            type: "radio",
                            name: "clock_first",
                            title: "出发打卡",
                            options: {
                                rules: [{
                                    required: false,
                                    message: ""
                                }],
                                initialValue: ""
                            },
                            list: [{
                                    key: "1",
                                    value: "是"
                                },
                                {
                                    key: "0",
                                    value: "否"
                                },
                            ]
                        },

                        {
                            type: "checkbox",
                            name: "clock_first_mode",
                            title: "打卡方式",
                            options: {
                                initialValue: []
                            },
                            list: [{
                                    key: 'photo',
                                    value: '拍照打卡'
                                },
                                {
                                    key: 'position',
                                    value: '定位打卡'
                                }
                            ]
                        },

                        {
                            type: "radio",
                            name: "clock_second",
                            title: "到达打卡",
                            options: {
                                rules: [{
                                    required: false,
                                    message: ""
                                }],
                                initialValue: ""
                            },
                            list: [{
                                    key: "1",
                                    value: "是"
                                },
                                {
                                    key: "0",
                                    value: "否"
                                },
                            ]
                        },
                        {
                            type: "checkbox",
                            name: "clock_second_mode",
                            title: "打卡方式",
                            options: {
                                initialValue: []
                            },
                            list: [{
                                    key: 'photo',
                                    value: '拍照打卡'
                                },
                                {
                                    key: 'position',
                                    value: '定位打卡'
                                }
                            ]
                        },
                        {
                            type: 'slot',
                            name: 'line_type'
                        },
                        {
                            type: "tree-select",
                            name: "department_id1",
                            title: "适用部门",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "tree-select",
                            name: "role_id1",
                            title: "适用岗位",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        // {
                        //     type: "select",
                        //     name: "role_id1",
                        //     title: "适用岗位",
                        //     mode: "default",
                        //     options: {},
                        //     list: [],
                        //     mode: 'multiple'
                        // },
                        {
                            type: "radio",
                            name: "clock_first1",
                            title: "入住打卡",
                            options: {
                                rules: [{
                                    required: false,
                                    message: ""
                                }],
                                initialValue: ""
                            },
                            list: [{
                                    key: "1",
                                    value: "是"
                                },
                                {
                                    key: "0",
                                    value: "否"
                                },
                            ]
                        },

                        {
                            type: "checkbox",
                            name: "clock_first_mode1",
                            title: "打卡方式",
                            options: {
                                initialValue: []
                            },
                            list: [{
                                    key: 'photo',
                                    value: '拍照打卡'
                                },
                                {
                                    key: 'position',
                                    value: '定位打卡'
                                }
                            ]
                        },

                        {
                            type: "radio",
                            name: "clock_second1",
                            title: "离店打卡",
                            options: {
                                rules: [{
                                    required: false,
                                    message: ""
                                }],
                                initialValue: ""
                            },
                            list: [{
                                    key: "1",
                                    value: "是"
                                },
                                {
                                    key: "0",
                                    value: "否"
                                },
                            ]
                        },
                        {
                            type: "checkbox",
                            name: "clock_second_mode1",
                            title: "打卡方式",
                            options: {
                                initialValue: ''
                            },
                            list: [{
                                    key: 'photo',
                                    value: '拍照打卡'
                                },
                                {
                                    key: 'position',
                                    value: '定位打卡'
                                }
                            ]
                        },
                    ]
                },
                detail: {
                    standard: [],
                    place: []
                }
            }
        },
        created() {
            this.get_info()
            this.$method.get_department().then(res => {
                this.form_data.list.forEach(item => {
                    let temp_arr = [{
                        key:0,
                        value:0,
                        title:'全部',
                    }]
                        if (item.name == "department_id"|| item.name == "department_id1") {
                            item.treeData = [...temp_arr,...res];
                        }
                });
            });
            // this.$method.get_role().then(res => {
            //     this.form_data.list.forEach(item => {
            //         if (item.name == "role_id" || item.name == "role_id1") {
            //                this.$config.status_all[0].key = 0;
            //             item.list = [...this.$config.status_all, ...res];
            //         }
            //     });
            // });
            this.$method.get_role_tree().then(res => {
                this.form_data.list.forEach(item => {
                    let temp_arr = [{
                        key:0,
                        value:0,
                        title:'全部',
                    }]
                    if (item.name == "role_id" || item.name == "role_id1") {
                        item.treeData = [...temp_arr,...res];
                    }
                });
            });
        },
        methods: {
            chhang_data(data,type) {
                let temp_arr = []
                if (data) {
                    let temp_data = data.split(',')
                    temp_data.forEach(item => {
                        if(type==1){
                             temp_arr.push(item)
                        }else {
                             temp_arr.push(Number(item))
                        }
                    })
                }
                return temp_arr
            },
            get_info() {
                getClockSetting().then(res => {
                    this.form_data.list.forEach(item=>{
                        if(item.name=='department_id'){
                              item.options.initialValue= this.chhang_data(res.data.list[0].data.department_id)
                        }
                        if(item.name=='department_id1'){
                              item.options.initialValue= this.chhang_data(res.data.list[1].data.department_id)
                        }
                        if(item.name=='role_id'){
                              item.options.initialValue= this.chhang_data(res.data.list[0].data.role_id)
                        }
                        if(item.name=='role_id1'){
                              item.options.initialValue= this.chhang_data(res.data.list[1].data.role_id)
                        }
                        if(item.name=='clock_first'){
                              item.options.initialValue= res.data.list[0].data.clock_first
                        }
                        if(item.name=='clock_first1'){
                              item.options.initialValue=  res.data.list[1].data.clock_first
                        }
                        if(item.name=='clock_first_mode'){
                              item.options.initialValue=  this.chhang_data(res.data.list[0].data.clock_first_mode,1)
                        }
                        if(item.name=='clock_first_mode1'){
                              item.options.initialValue=  this.chhang_data(res.data.list[1].data.clock_first_mode,1)
                        }
                        if(item.name=='clock_second'){
                              item.options.initialValue= res.data.list[0].data.clock_second
                        }
                        if(item.name=='clock_second1'){
                              item.options.initialValue= res.data.list[1].data.clock_second
                        }
                        if(item.name=='clock_second_mode'){
                              item.options.initialValue=  this.chhang_data( res.data.list[0].data.clock_second_mode,1)
                        }
                        if(item.name=='clock_second_mode1'){
                              item.options.initialValue=  this.chhang_data( res.data.list[1].data.clock_second_mode,1)
                        }
                    })
                })
            },
            handleSubmit(data) {
                let clock_setting = [{
                        "type": "place",
                        "data": {
                            "department_id": data.values.department_id.join(','),
                            "role_id": data.values.role_id.join(','),
                            "clock_first": data.values.clock_first,
                            "clock_first_mode": data.values.clock_first_mode.join(','),
                            "clock_second": data.values.clock_second,
                            "clock_second_mode": data.values.clock_second_mode.join(',')
                        }
                    },
                    {
                        "type": "order",
                        "data": {
                            "department_id": data.values.department_id1.join(','),
                            "role_id": data.values.role_id1.join(','),
                            "clock_first": data.values.clock_first1,
                            "clock_first_mode": data.values.clock_first_mode1.join(','),
                            "clock_second": data.values.clock_second1,
                            "clock_second_mode": data.values.clock_second_mode1.join(',')
                        }
                    }
                ]
                clockSetting({
                    data: {
                        clock_setting: clock_setting
                    },
                    info: true
                }).then(res => {
                    this.get_info()
                })
            },
            handleChange(e) {
                let target_num = e.e.target.value;
                let target_name = e.e.target.name
                if (e.e.target.name == "clock_first") {
                    if (target_num == 1) {
                        this.$refs.form_data.setFieldsValue({
                            clock_first_mode: ['photo', 'position']
                        });
                    } else {
                        this.$refs.form_data.setFieldsValue({
                            clock_first_mode: []
                        });
                    }
                }
                if (e.e.target.name == "clock_second") {
                    if (target_num == 1) {
                        this.$refs.form_data.setFieldsValue({
                            clock_second_mode: ['photo', 'position']
                        });
                    } else {
                        this.$refs.form_data.setFieldsValue({
                            clock_second_mode: []
                        });
                    }
                }
                if (e.e.target.name == "clock_first1") {
                    if (target_num == 1) {
                        this.$refs.form_data.setFieldsValue({
                            clock_first_mode1: ['photo', 'position']
                        });
                    } else {
                        this.$refs.form_data.setFieldsValue({
                            clock_first_mode1: []
                        });
                    }
                }
                if (e.e.target.name == "clock_second1") {
                    if (target_num == 1) {
                        this.$refs.form_data.setFieldsValue({
                            clock_second_mode1: ['photo', 'position']
                        });
                    } else {
                        this.$refs.form_data.setFieldsValue({
                            clock_second_mode1: []
                        });
                    }
                }
            },

        }
    }
</script>

<style lang="less" scoped>
    .time_line {
        max-width: 600px;
    }

    .avatar_ul {
        display: flex;
        flex-wrap: wrap;

        .avatar {
            margin: 16px 10px 0;
            width: 80px;
        }
    }

    .page_detail {
        margin: 0;
        margin-left: 5%;

    }

    .li_item {
        width: 100%;

        .title_item {
            width: 80px;
            text-align: left;
            display: inline-block;
            color: #000;
        }

        margin-bottom: 30px;
    }

    .arpproval_title {
        padding: 15px 0;

        text-align: center;
        background: rgba(245, 245, 245, 1);
        font-size: 14px;

        span {
            width: 6%;
            display: inline-block;
        }

        .department_appr {
            width: 25%;
        }
    }

    .arpproval_title1 {
        height: auto;
        background: none;
        border: 1px solid rgba(245, 245, 245, 1);
    }

    .go_see {
        color: #1890ff;
    }
</style>
